import { createContext, useContext } from 'react';
import Global from './Global';
import ConfigMap from './rootEnum';

export class RootStore {
  public global = new Global();
  
  // 全局下拉框数据
  configMap = new ConfigMap();
}

export const rootStoreContext = createContext<RootStore | null>(null);

const useRootStore = () => {
  const rootStore = useContext(rootStoreContext);
  if (rootStore === null)
    throw new Error('You forgot to use RootStore Provider!');
  return rootStore;
};

export const rootStore = new RootStore();

export default useRootStore;
