import { getEnumList } from '../services/common';
import { Dayjs } from 'dayjs';
/* eslint-disable @typescript-eslint/member-ordering */
import { makeAutoObservable, runInAction } from 'mobx';

export interface Config {
    name: string;
    value: string;
    color?: string;
}

export default class ConfigMap {
    [x: string]: any;
    loading = true;

    enum_info: any;

    enumDataTransfer = (type: string, v?: string) => {
        if (this.enum_info[type])
            return this.enum_info[type].find((item: Config) => item.value === v)?.name || '';
        return '';
    }

    formatDayjs(value?: Dayjs) {
        return value?.format?.('lll');
    }

    async init() {
        try {
            // 筛选的枚举数据
            const data = await getEnumList();
            runInAction(() => {
                this.enum_info = data;
                this.loading = false;
            });
        }
        catch (error) {
            console.log(error);
            this.loading = false;
        }
    }

    constructor() {
        makeAutoObservable(this);
    }
}
