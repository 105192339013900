import ProLayout from '@ant-design/pro-layout';
import { Menu, Divider } from 'antd';
import logo from '@ant-design/pro-layout/es/assets/logo.svg';
import { useObserver, useLocalStore } from 'mobx-react-lite';
import React, { FC, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import useRootStore from '../../models';
import './index.scss';
import menuData from '../../assets/menuData';
import RightContent from './RightContent';
import { ClusterOutlined } from '@ant-design/icons';

const BasicLayout: FC = (props) => {
  const location = useLocation();
  const rootStore = useRootStore();
  const { SubMenu } = Menu;
  // const store = useLocalStore(() => ({
  //   defaultPlugin: [] as any,
  //   plugins: [] as any,
  //   changePligin: (val: string) => {
  //     window.location.href = val
  //   },
  //   getPlugin: (value: string, arr: any, parentObject?: any) => {
  //     const object = arr.find((item: any) => item['routePath'] ? item['routePath'] === value : item.path === value)
  //     console.log(object, 6666, '当前要加载的插件')
  //     let plugin = [] as any
  //     if(object) {
  //       plugin = []
  //       const pluginName = parentObject ? store.getGroup(parentObject.name, menuData) : store.getGroup(object.name, menuData)
  //       console.log(pluginName, '组的名称')
  //       pluginName.map((item: any) => {
  //         if(store.getFlatArr(rootStore.global.menu).find((val: any) => val.name === item.name)) {
  //           plugin.push(store.getFlatArr(rootStore.global.menu).find((val: any) => val.name === item.name))
  //         }
  //       })
  //     } else {
  //       for (let key of arr) {
  //         if(key.routes) {
  //           plugin = store.getPlugin(value, key.routes, key)
  //         }
  //         if(plugin && plugin.length > 0){
  //           break
  //         }
  //       }
  //     }
  //     return plugin
  //   },
  //   getGroup: (name: string, menuData: any) => {
  //     let groupInfo = [] as any
  //     Object.keys(menuData).map((item: string) => {
  //       if(menuData[item].find((val: any) => val.name === name)) {
  //         menuData[item].map((val: any) => {
  //           groupInfo.push(val)
  //         })
  //       }
  //     })
  //     return groupInfo
  //   },
  //   getFlatArr: (arr: any) => {
  //     while(arr.some((item: any) => Array.isArray(item))) {
  //       arr = [].concat(...arr)
  //     }
  //     return arr
  //   }
  // }))
  useEffect(() => {
    console.log(rootStore.global.menu)
    // store.defaultPlugin = store.getFlatArr(rootStore.global.menu).filter((item: any) => item.name === '总览' || item.name === '系统配置') 
    // const firstPlugin = store.getFlatArr(rootStore.global.menu).filter((item: any) => item.name === '本体管理' || item.name === '实体管理') 
    // menuData[Object.keys(menuData)[0]].map((val: any) => {
    //   if(store.getFlatArr(rootStore.global.menu).find((item: any) => item.name === val.name)) {
    //     firstPlugin.push(store.getFlatArr(rootStore.global.menu).find((item: any) => item.name === val.name))
    //   }
    // })
    // console.log(firstPlugin)
    // store.plugins = store.defaultPlugin
    // const targetPlugin = store.getPlugin(location.pathname, store.getFlatArr(rootStore.global.menu))
    // console.log(location.pathname,targetPlugin)
    // if(targetPlugin.length > 0) {
    //   targetPlugin.map((item: any) => {
    //     if(store.defaultPlugin.find((val: any) => val.name === item.name)) return
    //     store.plugins.push(item)
    //   })
    // } else {
    //   store.plugins = [...store.defaultPlugin, ...firstPlugin]
    // }
    // console.log(store.plugins)
  }, [rootStore.global.menu,props]);

  return useObserver(() => {
    return <ProLayout
      logo={<img src={logo} alt="" />}
      // @ts-ignore
      route={{ path: '/', routes: rootStore.global.menu }}
      menuItemRender={props => {
        return (<Link to={props.path || ''}>
          {props.icon}
          <span>{props.name}</span>
          </Link>)

        // return props.name === '总览' ? (
        //   <Menu theme="dark" className="pandectMenu">
        //     <SubMenu key="sub1" icon={props.icon} title="总览">
        //       <div className='menuBox'>
        //         <div>
        //           {Object.keys(menuData).map(item => (
        //             <ul key={item}>
        //               <p>{item}</p>
        //               {menuData[item].map((val: any) => (
        //                 <li key={val.name} onClick={() => store.changePligin(val.path)}><ClusterOutlined style={{marginRight: '6px'}} />{val.name}</li>
        //               ))}
        //               <Divider style={{margin: '8px 0', borderColor: '#333'}} />
        //             </ul>
        //           ))}
        //         </div>
        //       </div>
        //     </SubMenu>
        //   </Menu>
        // ) : (
        // <Link to={props.path || ''}>
        //   {props.icon}
        //   <span>{props.name}</span>
        // </Link>
        // )
      }}
      location={location}
      title={rootStore.global.systemTitle}
      rightContentRender={() => <RightContent />}
      itemRender={props => <Link to={props.path}>{props.breadcrumbName}</Link>}
      footerRender={false}
      // footerRender={() => <DefaultFooter copyright="" links={[]} />}
      {...rootStore.global.layoutSettings}
    >
      {props.children}
    </ProLayout>
  });
};

export default BasicLayout;
