import { ProMenuExport } from '../../menu';
import { HomeOutlined } from '@ant-design/icons';
import React from 'react';

const menu: ProMenuExport = {
  path: '/',
  name: '首页',
  component: () => import('./Home'),
  // icon: 'home',
  icon: <HomeOutlined />,
};

export default menu;
