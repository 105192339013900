import request from '../utils/request';

type Values = { value: string; name: string }[];

// 获取全局枚举值
export const getEnumList = () =>
    request.get('/auth-api/v1/t_enum_list').then(({ data }) => {
        const { enum_info } = data;
        const result: Record<string, Values> = Object.fromEntries(
            Object.entries(enum_info).map(([k, v]: any) => [k, v.items]),
        );
        return result;
    });