import { SettingOutlined, UserOutlined, LogoutOutlined, LockOutlined } from '@ant-design/icons';
import { Avatar, Dropdown, Menu } from 'antd';
import classnames from 'classnames';
import { useObserver } from 'mobx-react-lite';
import React, { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useRootStore from '../../models';
import { logout } from '../../services/login';
import ChangePassword from './ChangePassword';
import styles from './RightContent.module.scss';

const RightContent: FC = () => {
  const rootStore = useRootStore();
  const navigate = useNavigate();
  const [passwordVisible, setPasswordVisible] = useState(false);
  const userDropDown = (
    <Menu>
      {/* <Menu.Item key="userCenter">
        // <Icon type="user" />
        <span>用户中心</span>
      </Menu.Item>
      <Menu.Item key="userinfo">
        <Icon type="setting" />
        <span>用户设置</span>
      </Menu.Item> */}
      {rootStore.global.userMenus?.map((item: any) => (
        <Menu.Item key='{item.key}'>
          <SettingOutlined />
          <span><a href={item.url} target='blank'>{item.title}</a></span>
        </Menu.Item>
      ))}

      <Menu.Item key="userinfo">
        <SettingOutlined />
        <span onClick={() => navigate('/sys_config/layout_settings')}>界面设置</span>
      </Menu.Item>

      <Menu.Item key="userinfo">
        <LockOutlined />
        <span onClick={() => {
          setPasswordVisible(true);
        }}>修改密码</span>
      </Menu.Item>
      {/* <Menu.Item key="triggerError">
        <Icon type="close-circle" />
        <span>Trigger Error</span>
      </Menu.Item> */}
      {/* <Menu.Divider /> */}
      <Menu.Item
        key="logout"
        onClick={() => {
          logout();
          navigate('/login');
          rootStore.global.currentUser = undefined;
        }}
      >
        {/* <Icon type="logout" /> */}
        <LogoutOutlined />
        退出登录
      </Menu.Item>
    </Menu>
  );
  return useObserver(() => (
    <div
      className={classnames(
        styles.right,
        rootStore.global.layoutSettings.layout === 'topmenu'
        && rootStore.global.layoutSettings.navTheme === 'dark'
        && styles.dark,
      )}
    >
      {/* <HeaderSearch
        className={`${styles.action} ${styles.search}`}
        placeholder="站内搜索"
        dataSource={['搜索提示一', '搜索提示二', '搜索提示三']}
        onSearch={value => {
          console.log('input', value); // eslint-disable-line
        }}
        onPressEnter={value => {
          console.log('enter', value); // eslint-disable-line
        }}
      /> */}
      {/* <Tooltip title="使用文档">
        <a
          target="_blank"
          href="https://pro.ant.design/docs/getting-started"
          rel="noopener noreferrer"
          className={styles.action}
          title="使用文档"
        >
          <Icon type="question-circle-o" />
        </a>
      </Tooltip> */}
      {/* <NoticeIcon
        className={styles.action}
        count={currentUser.notifyCount}
        onItemClick={(item, tabProps) => {
          console.log(item, tabProps); // eslint-disable-line
        }}
        onClear={onNoticeClear}
        onPopupVisibleChange={onNoticeVisibleChange}
        loading={fetchingNotices}
        popupAlign={{ offset: [20, -16] }}
      >
        <NoticeIcon.Tab
          list={noticeData['通知']}
          title="通知"
          emptyText="你已查看所有通知"
          emptyImage="https://gw.alipayobjects.com/zos/rmsportal/wAhyIChODzsoKIOBHcBk.svg"
        />
        <NoticeIcon.Tab
          list={noticeData['消息']}
          title="消息"
          emptyText="您已读完所有消息"
          emptyImage="https://gw.alipayobjects.com/zos/rmsportal/sAuJeJzSKbUmHfBQRzmZ.svg"
        />
        <NoticeIcon.Tab
          list={noticeData['待办']}
          title="待办"
          emptyText="你已完成所有待办"
          emptyImage="https://gw.alipayobjects.com/zos/rmsportal/HsIsxMZiWKrNUavQUXqx.svg"
        />
      </NoticeIcon> */}
      {/* {currentUser.name ? (
        <Dropdown overlay={menu}>
          <span className={`${styles.action} ${styles.account}`}>
            <Avatar size="small" className={styles.avatar} src={currentUser.avatar} alt="avatar" />
            <span className={styles.name}>{currentUser.name}</span>
          </span>
        </Dropdown>
      ) : (
        <Spin size="small" style={{ marginLeft: 8, marginRight: 8 }} />
      )} */}
      <Dropdown overlay={userDropDown}>
        <span className={classnames(styles.action, styles.account)}>
          <Avatar
            size="small"
            className={styles.avatar}
            alt="avatar"
            icon={<UserOutlined />}
          />
          
          {rootStore.global.currentUser?.true_name}
        </span>
      </Dropdown>
      {/* <Button
        size="small"
        ghost={themeColor === 'dark'}
        style={{
          margin: '0 8px',
        }}
        onClick={() => {
          this.changLang();
        }}
      >
        <FormattedMessage id="navbar.lang" />
      </Button> */}

      <ChangePassword
        onClose={() => {
          setPasswordVisible(false);
        }}
        visible={passwordVisible}
      />
    </div>
  ));
};

export default RightContent;
