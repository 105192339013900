import request from '../utils/request';
import { mainframeApiHost } from './index';

export const getPluginRootPath = (pluginKey: string) =>
  `/_plugins/${pluginKey}/`;

export interface PluginType {
  plugin_id: number;
  plugin_name: string;
  plugin_key: string;
  description: string;
  build_time?: number;
  enable_status: 'enable' | 'disable';
}

export const fetchPluginList = () => {
  return request.get<{ total: number; items: PluginType[]; sorted_menu: any, title: any }>(
    '/t_plugin_info?rd=' + new Date().getTime(),
  );
};


export const fetchGlobalInfo = () => {
  return request.get('/t_global_info?rd=' + new Date().getTime());
};