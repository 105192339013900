import { message, notification } from 'antd';
import axios, { AxiosError } from 'axios';
// @ts-ignore
import { stringify } from 'qs';
import React from 'react';

const request = axios.create({
  paramsSerializer: params => stringify(params, { arrayFormat: 'comma' }),
});

request.interceptors.request.use(req => {
  // if (getToken()) req.headers.Authorization = getToken();
  return req;
});


request.interceptors.response.use(
  response => {
    if (!response.data.success || !response.data.data) {
      // notification.error({
      //   message: `请求失败！`,
      //   description: (
      //     <>
      //       <div>
      //         <b>url: </b>
      //         {response.config.url}
      //       </div>
      //       <div>
      //         <b>code: </b>
      //         {response.data?.code ?? response.status}
      //       </div>
      //       <div>
      //         <b>message: </b>
      //         {response.data?.message}
      //       </div>
      //     </>
      //   ),
      // });

      if (response.data?.code == 401) {
        message.error(
          <span>
            登陆过期或已失效！请重新登陆
          </span>
        )
      }
      else if (response.data?.code == 200 || response.data?.code == "") {
        message.error(
          <span>
            {response?.data?.message}
          </span>
        )
      }
      else {
        message.error(
          <span>
            {response?.data?.message}<span title={response.config.url}>(code:{response.data?.code ?? response.status})</span>
          </span>
        )
      }

      return Promise.reject(response);
    }
    return { ...response, data: response.data.data };
  },
  (error: AxiosError) => {
    if (error.response) {

      if (error.response.status === 401) {
        message.error(
          <span>
            登陆过期或已失效！请重新登陆
          </span>
        )
      }
      else if (error.response.status === 200) {
        message.error(
          <span>
            {error.response.statusText}<span title={error.response.config.url}></span>
          </span>
        )
      }
      else {
        message.error(
          <span>
            {error.response.statusText}<span title={error.response.config.url}>(code:{error.response.status})</span>
          </span>
        )
      }


      // notification.error({
      //   message: `执行失败`,
      //   description: (
      //     <>
      //       <div>
      //         {error.response.statusText}<span title={error.response.config.url}>({error.response.status})</span>
      //       </div>
      //     </>
      //   ),
      // });
    } else if (error.request) {
      message.error(
        <span>
          请求发送失败！{error.config.url}
        </span>
      )
      // notification.error({
      //   message: `请求发送失败！`,
      //   description: (
      //     <p>
      //       {error.config.url}
      //     </p>
      //   ),
      // });
    } else {
      message.error(
        <span>
          请求配置错误！{error.config.url}
        </span>
      )
      // notification.error({
      //   message: `请求配置错误！`,
      //   description: (
      //     <p>
      //       {error.config.url}
      //     </p>
      //   ),
      // });
    }
    return Promise.reject(error);
  },
);

export default request;
