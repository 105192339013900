/* eslint-disable prettier/prettier */
/* eslint-disable no-continue */
import React, { ComponentType, lazy, Suspense } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

interface ProMenuChildType {
  /**
   * 页面组件，当拥有子路由时，子页面以children传入
   */
  component?:
  | (() => Promise<{ default: React.ComponentType }>)
  | { default: React.ComponentType };
  /**
   * 菜单跳转路径，如果未设置routePath，则同时也为路由路径
   */
  path?: string;
  /**
   * 路由路径，当路径包含变量时使用
   */
  routePath?: string;
  /**
   * 菜单项名称，同时影响页面标题和面包屑
   */
  name: string;
  /**
   * 菜单图表，ant design icon组件
   */
  icon?: React.ReactNode;
  /**
   * 子菜单
   */
  routes?: ProMenuChildType[];
  /**
   * 在菜单中隐藏该项
   */
  hideInMenu?: boolean;
  /**
   * 在面包屑中隐藏
   */
  hideInBreadcrumb?: boolean;
}

export interface ProMenuType extends ProMenuChildType {
  icon: React.ReactNode;
}

export type ProMenuExport = ProMenuType | ProMenuType[];

export const processMenuItem = (menuItem: ProMenuChildType) => {
  // eslint-disable-next-line react/jsx-no-useless-fragment
  let WrapperComponent: ComponentType = ({ children }) => <>{children}</>;
  if (menuItem.component) {
    if ('default' in menuItem.component) {
      WrapperComponent = menuItem.component.default;
    } else {
      WrapperComponent = lazy(menuItem.component);
    }
  }
  const children = menuItem.routes ? (
    <Routes>
      {menuItem.routes.map(processMenuItem)}
      <Route element={<Navigate to={menuItem.routes[0].path || ''} />} />
    </Routes>
  ) : undefined;
  return (
    <Route
      path={menuItem.routePath || menuItem.path}
      key={menuItem.routePath || menuItem.path}
      element={(
        <Suspense fallback={null}>
          <WrapperComponent>{children}</WrapperComponent>
        </Suspense>
      )}
    />
  );
};
