import { ConfigProvider } from 'antd';
import antd_zh from 'antd/es/locale-provider/zh_CN';
import { createBrowserHistory, Update } from 'history';
import { observer } from 'mobx-react-lite';
import React, { FC, useEffect, useLayoutEffect, useReducer, useState } from 'react';
import { Route, Router, Routes } from 'react-router-dom';
import BasicLayout from './components/BasicLayout';
import { RootStore, rootStoreContext, rootStore } from './models';
import Login from './pages/Login/Login';
import 'antd/dist/antd.css';
import './utils/defineModules';
import './utils/setupMomentLocale';

export const history = createBrowserHistory();

const BrowserRouter: FC = ({ children }) => {
  let [state, dispatch] = useReducer((_: Update, action: Update) => action, {
    action: history.action,
    location: history.location,
  });

  useLayoutEffect(() => history.listen(dispatch), []);
  return (
    <Router action={state.action} location={state.location} navigator={history}>
      {children}
    </Router>
  );
};

const Main = observer(() => {
  useEffect(() => {
    rootStore.global.fetchCurrentUser();
  }, [rootStore.global]);

  useEffect(() => {
    if (rootStore.global.currentUser) {
      rootStore.global.fetchModules();
    }
    if (rootStore.global.currentUser)
      rootStore.configMap.init();
  }, [rootStore.global.currentUser]);

  return rootStore.global.currentUser && !rootStore.configMap.loading ? (
    <BasicLayout>
      <Routes>{rootStore.global.routes}</Routes>
    </BasicLayout>
  ) : null;
});
const App = () => {
  return (
    <ConfigProvider locale={antd_zh}>
      <BrowserRouter>
        <rootStoreContext.Provider value={rootStore}>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="*" element={<Main />} />
          </Routes>
        </rootStoreContext.Provider>
      </BrowserRouter>
    </ConfigProvider>
  );
};

export default App;
