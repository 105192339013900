import request from '../utils/request';

const authApiHost = '/auth-api/v1/admin';
const api_host = '/auth-api/v1';

export interface CurrentUser {
  user_id: number,
  true_name: string;
  percode_list: string[];
  description: string;
}
export const fetchCurrentUser = () =>
  request.get<CurrentUser>(`${authApiHost}/current_user`);

export const login = (value: {
  username: string;
  password: string;
  remember?: boolean;
}) =>
  request.post<{ token: string }>(`${authApiHost}/login`, {
    app_id: 'Mf7JY8WcB5vSMPdR',
    ...value,
  });

export const refresh_verify_code = () =>
  request.get(`${api_host}/kaptcha?rnd=` + new Date().getTime());

export const changePassword = (value: {
  old_pwd: string;
  new_pwd: string;
  new_pwd2: string;
}) =>
  request.post<any>(`${authApiHost}/change_password`, {
    app_id: 'Mf7JY8WcB5vSMPdR',
    ...value,
  });

export const logout = () => {
  window.localStorage.removeItem('token');
  return request.post(`${authApiHost}/logout`);
};
