import { useLocalStore } from 'mobx-react-lite';
import { Form, Input, Modal, message, Switch, Transfer } from 'antd';
import React, { useState, useEffect } from 'react';
import request from '../../utils/request';
// import { useRequest } from 'ahooks';
// import { changePassword } from '../../services/user';
import { PlusOutlined, ReloadOutlined, EditOutlined, DeleteOutlined, LockOutlined } from '@ant-design/icons';
import { changePassword } from '../../services/login';

export interface ChangePasswordProps {
  visible: boolean;
  // userId: number;
  onClose: () => void;
  // onRefresh: () => void;
}

export default function ChangePassword({
  visible,
  onClose,
  // onRefresh,
}: ChangePasswordProps) {
  const [form] = Form.useForm();
  const [submitting, setSubmitting] = useState(false);
  // const { run, loading } = useRequest(
  //   (user_id, login_pwd, login_pwd_confirm) => changePassword(user_id, login_pwd, login_pwd_confirm),
  //   {
  //     manual: true,
  //     onSuccess: (res: any) => {
  //       console.log(res, 'success');

  //       message.success("修改成功！");
  //       onClose();
  //       onRefresh();
  //     }
  //   },
  // );

  const handleSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        setSubmitting(true);
        changePassword({
          old_pwd: values.old_pwd,
          new_pwd: values.new_pwd,
          new_pwd2: values.new_pwd2
        }).then(
          res => {
            setSubmitting(false);
            message.success('修改成功！');
            onClose();
          },
        ).finally(()=>{
          setSubmitting(false);
        });
      })
      .catch((info) => {
        console.log('Validate Failed:', info);
      });
  };

  const store = useLocalStore(() => ({
    loading: true,
    initialValues: {
      password: '',
      passwordConfirm: '',
    },
  }));
  return (
    <Modal
      visible={visible}
      onCancel={() => {
        form.resetFields();
        onClose();
      }}
      title={
        <>
          <LockOutlined />修改密码
        </>
      }
      onOk={handleSubmit}
      okButtonProps={{ loading: submitting }}
      maskClosable={false}
    >
      <Form
        form={form}
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
        initialValues={store.initialValues}
      >
        <Form.Item
          label="输入原密码"
          name="old_pwd"
          hasFeedback
          rules={[
            { required: true, message: '请输入原密码!' }
          ]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          label="输入新密码"
          name="new_pwd"
          hasFeedback
          rules={[
            { required: true, message: '请输入新密码!' },
            ({ getFieldValue }) => ({
              validator(rule, value) {
                if (value && value.length < 6)
                  return Promise.reject('密码长度不能小于6位！');
                return Promise.resolve();
              },
            })
          ]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          label="再次输入新密码"
          name="new_pwd2"
          dependencies={['new_pwd']}
          hasFeedback
          rules={[
            {
              required: true,
              message: '请再次输入新密码!',
            },
            ({ getFieldValue }) => ({
              validator(rule, value) {
                if (!value || getFieldValue('new_pwd') === value) {
                  return Promise.resolve();
                }
                return Promise.reject('两次密码不一致!');
              },
            }),
          ]}
        >
          <Input.Password />
        </Form.Item>
      </Form>
    </Modal>
  );
}
