import { LockOutlined, SafetyCertificateOutlined, UserOutlined } from '@ant-design/icons';
import { DefaultFooter } from '@ant-design/pro-layout';
import logo from '@ant-design/pro-layout/es/assets/logo.svg';
import { Alert, Button, Checkbox, Form, Input, Tooltip } from 'antd';
import { runInAction } from 'mobx';
import { useLocalStore, useObserver } from 'mobx-react-lite';
import React, { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { rootStore } from '../../models';
import { setToken } from '../../services';
import { login } from '../../services/login';
import CaptchaInput from './CaptchaInput';
import styles from './Login.module.scss';
import { v4 as uuidv4 } from 'uuid';

const Login: FC = () => {

  useEffect(() => {
    document.title = "系统登陆";
    rootStore.global.fetchGlobalInfo();
  }, []);

  const [captcha_id, setCaptchaID] = useState(() => uuidv4());
  const navigate = useNavigate();
  const store = useLocalStore(() => ({
    submitting: false,
    form: {
      username: '',
      password: '',
      verify_code: '',
      remember: true,
    },
    showEmptyError: {
      username: false,
      password: false,
      verify_code: false,
    },
    errorMessage: '',
    login() {
      if (store.form.username && store.form.password && store.form.verify_code) {
        store.submitting = true;
        store.errorMessage = '';
        login(store.form).then(
          ({ data: { token } }) => {
            setToken(token);
            store.submitting = false;
            navigate('/');
          },
          res => {
            runInAction(() => {
              setCaptchaID(uuidv4());
              store.submitting = false;
              store.errorMessage = res.data?.message;
            });
          },
        );
      } else {
        if (!store.form.verify_code) store.showEmptyError.verify_code = true;
        if (!store.form.username) store.showEmptyError.username = true;
        if (!store.form.password) store.showEmptyError.password = true;
      }
    },
  }));
  return useObserver(() => (
    <div className={styles.loginPage}>
      <div className={styles.formWrapper}>
        <header className={styles.header}>
          <img src={logo} alt="logo" className={styles.logo} />
          <span className={styles.title}>{rootStore.global.systemTitle}</span>
        </header>
        <Form>
          {/* {store.errorMessage && (
            <Alert
              message={store.errorMessage}
              type="error"
              showIcon
              className={styles.errorMessage}
            />
          )} */}
          <Form.Item
            help={store.showEmptyError.username && '请输入用户名'}
            validateStatus={store.showEmptyError.username ? 'error' : undefined}
            hasFeedback
          >
            <Input
              value={store.form.username}
              onChange={e => {
                store.form.username = e.target.value;
                if (e.target.value) store.showEmptyError.username = false;
              }}
              prefix={<UserOutlined />}
              placeholder="请输入用户名"
              size="large"
              autoComplete="username"
            />
          </Form.Item>
          <Form.Item
            help={store.showEmptyError.password && '请输入密码'}
            validateStatus={store.showEmptyError.password ? 'error' : undefined}
            hasFeedback
          >
            <Input
              value={store.form.password}
              onChange={e => {
                store.form.password = e.target.value;
                if (e.target.value) store.showEmptyError.password = false;
              }}
              prefix={<LockOutlined />}
              placeholder="请输入密码"
              size="large"
              type="password"
              autoComplete="current-password"
            />
          </Form.Item>
          <Form.Item
            help={store.showEmptyError.verify_code && '请输入验证码'}
            validateStatus={store.showEmptyError.verify_code ? 'error' : undefined}
            hasFeedback
          >
            <Input
              size="large"
              value={store.form.verify_code}
              onChange={e => {
                store.form.verify_code = e.target.value;
                if (e.target.value) store.showEmptyError.verify_code = false;
              }}
              placeholder="请输入验证码"
              className={styles.addonNoPadding}
              autoComplete="off"
              prefix={<SafetyCertificateOutlined />}
              addonAfter={
                <Tooltip title="点击刷新">
                  <img
                    onClick={() => setCaptchaID(uuidv4())}
                    style={{ height: 38, cursor: 'pointer' }}
                    src={`/auth-api/v1/kaptcha?uuid=${captcha_id}`}
                  />
                </Tooltip>
              }
            />
          </Form.Item>
          {/* <Form.Item name="captchaComp" rules={[{
            validateTrigger: 'onBlur',
            validator: async (rule, value) => {
              // console.log(rule)
              if (value.captchaCode == null || value.captchaCode.length <= 0) {
                throw new Error('请输入验证码!');
              }
            }
          },]}>
            <CaptchaInput />
          </Form.Item> */}
          {/* <Form.Item>
            <Checkbox
              checked={store.form.remember}
              onChange={e => {
                store.form.remember = e.target.checked;
              }}
            >
              自动登录
            </Checkbox>
          </Form.Item> */}
          <Form.Item>
            <Button
              type="primary"
              block
              loading={store.submitting}
              onClick={store.login}
              htmlType="submit"
              size="large"
            >
              登录
            </Button>
          </Form.Item>
        </Form>
        {/* <DefaultFooter copyright="2020 - " links={[]} /> */}
      </div>
    </div>
  ));
};

export default Login;
