
export const mainframeApiHost = '/mainframe-api/v1';

export const setToken = (t: string) => {
  window.localStorage.setItem('token', t);
};
export const getToken = () => {
  const token = window.localStorage.getItem('token');
  if (token) return `bearer ${token}`;
  // return und
};
