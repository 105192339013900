import { ProMenuExport } from '../../menu';

const menu: ProMenuExport = {
  name: '系统配置',
  path: 'sys_config/*',
  icon: 'setting',
  routes: [
    {
      name: '界面设置',
      path: 'layout_settings',
      component: () => import('./LayoutSetting'),
    },
  ],
  hideInMenu: true,
};
export default menu;
